<template>
  <div class="unconfirmed-item">
    <div class="unconfirmed-item__content">
      <div class="unconfirmed-item__heading">
        <h2>{{ title }}</h2>
        <p>
          {{ description }}
        </p>
      </div>

      <div class="unconfirmed-item__actions">
        <div class="unconfirmed-item__btns">
          <button
            class="unconfirmed-item__accept"
            @click="onConfirmReservation"
          >
            Підтвердити
          </button>
          <VDialog
            width="500"
            v-model="dialog"
            eager
            @update:model-value="(e) => onChangeEmployeeDialog(e)"
          >
            <template #activator="{ props }">
              <button class="unconfirmed-item__change" v-bind="props">
                {{
                  reservation.employees.length
                    ? "Змінити працівника"
                    : "Вибрати працівника"
                }}
              </button>
            </template>
            <VCard>
              <VCardTitle>
                <span class="text-h5">{{
                  reservation.employees.length
                    ? "Змінити працівника"
                    : "Вибрати працівника"
                }}</span>
              </VCardTitle>
              <VCardText>
                <v-container>
                  <VRow>
                    <VCol cols="12">
                      <VSelect
                        :error="!!employeeErrorMessage.length"
                        :error-messages="employeeErrorMessage"
                        :loading="employeeLoading"
                        :items="companyStore.employees"
                        item-title="name"
                        item-value="id"
                        label="Працівник"
                        multiple
                        required
                        v-model="selectedEmployee"
                      >
                        <template #no-data>
                          <p>Працівників не знайдено</p>
                        </template>
                      </VSelect>
                    </VCol>
                  </VRow>
                </v-container>
              </VCardText>
              <v-card-actions>
                <v-spacer></v-spacer>
                <VBtn
                  color="blue-darken-1"
                  variant="text"
                  @click="closeDialog(false)"
                >
                  Відміна
                </VBtn>
                <VBtn
                  color="blue-darken-1"
                  variant="text"
                  @click="closeDialog(true)"
                >
                  Зберегти
                </VBtn>
              </v-card-actions>
            </VCard>
          </VDialog>
        </div>
      </div>
    </div>
    <div class="unconfirmed-item__employee">
      <AvatarsGroup :items="prepAvatars"></AvatarsGroup>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from "vue";
import { Employee } from "../types/employee.type";
import AvatarsGroup from "./avatars-group.vue";
import axios from "axios";
import {
  confirmReservation,
  getCompanyEmployees,
  updateReservation,
} from "../services/api.service";
import { useCompanyStore } from "../stores/company.store";
import { Reservation } from "../types/reservation.type";

interface Props {
  title: string;
  description: string;
  employees?: Employee[];
  reservation: Reservation;
}

const props = withDefaults(defineProps<Props>(), {
  title: "",
});

const emit = defineEmits<{
  (e: "updateReservation", value: Reservation): void;
  (e: "confirmReservation", value: Reservation): void;
}>();

const employeeLoading = ref(false);
const selectedEmployee = ref<number[]>([]);
const employeeErrorMessage = ref("");
const dialog = ref(false);
const companyStore = useCompanyStore();

const prepAvatars = computed<
  {
    alt: string;
    tooltip: string;
    img: string;
  }[]
>(() => {
  if (props.employees && props.employees.length) {
    const avatars = props.employees?.map((employee) => {
      return {
        alt: employee.name || "Unknown",
        tooltip: employee.name || "Unknown",
        img: (employee.avatar as string) || "",
      };
    });

    return avatars;
  }

  return [
    {
      alt: "Не визначений",
      tooltip: "Не визначений",
      img: "",
    },
  ];
});

const onChangeEmployeeDialog = async (opened: boolean) => {
  const filteredEmployees = props.reservation.employees.filter(
    (employee) => employee.id
  );
  selectedEmployee.value =
    filteredEmployees.map((employee) => employee.id!) || [];
  if (opened && !companyStore.employees.length) {
    employeeLoading.value = true;
    try {
      const employees = await getCompanyEmployees(
        companyStore.currentCompany?.id!
      );

      companyStore.employees = employees;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        employeeErrorMessage.value = `${error.message}`;
      } else {
        employeeErrorMessage.value = "Error";
        console.log(error);
      }
    }

    employeeLoading.value = false;
  }
};

const closeDialog = async (shouldSave: boolean) => {
  if (shouldSave) {
    try {
      const updatedReservation = await updateReservation({
        id: props.reservation.id,
        employee_ids: selectedEmployee.value,
      });

      emit("updateReservation", {
        ...props.reservation,
        employees: updatedReservation.employees,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        employeeErrorMessage.value = error.message;
      } else {
        employeeErrorMessage.value = "Error";
        console.log(error);
      }
    }
  }
  selectedEmployee.value = [];
  dialog.value = false;
};

const onConfirmReservation = async () => {
  try {
    const reserv = await confirmReservation(props.reservation.id);

    emit("confirmReservation", reserv);
  } catch (error) {}
};
</script>

<style scoped lang="scss">
.unconfirmed-item {
  border-radius: 10px;
  background: #e7eaee;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  &__content {
  }

  &__heading {
    h2 {
      color: #343d55;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px; /* 100% */
    }

    p {
      color: #353c4b;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 14.4px */
    }
  }

  &__employee {
    img {
      width: 24px;
      height: 24px;
    }
  }

  &__btns {
    display: flex;
    column-gap: 8px;

    button {
      border-radius: 4px;
      background: #fff;
      padding: 12px 24px;
      color: #343d55;
      text-align: center;
      font-family: "Inter";
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px; /* 100% */
    }
  }

  button.unconfirmed-item__change {
    background-color: #4c4e51;
    color: #fff;
  }
}
</style>
