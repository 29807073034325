<template>
  <div class="schedules">
    <div class="schedules__content">
      <div class="schedules__calendar is-light-mode">
        <Qalendar
          :events="events"
          :config="config"
          :is-loading="loading"
          @updated-period="periodUpdated"
          @datetime-was-clicked="openCreateSidebar"
        >
          <!-- @event-was-clicked="console.log" -->
          <template #weekDayEvent="eventProps">
            <CustomEvent :event="eventProps.eventData" :config="config" />
          </template>

          <template #eventDialog="props">
            <EventDialog :eventProps="props" @edit="editReservation" />
          </template>
        </Qalendar>
      </div>
    </div>

    <div class="schedules__sidebar" :class="{ 'sidebar-hidden': hideSidebar }">
      <div class="schedules__sidebar-content">
        <div class="schedules__sidebar-header">
          <h3>Резервування</h3>
          <VIcon
            icon="mdi-close"
            @click="
              hideSidebar = !hideSidebar;
              resetEditedReservation();
            "
          />
        </div>
        <div class="schedules__sidebar-form">
          <div>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="editedReservation.start_date"
                label="Start Date"
                type="datetime-local"
                required
              ></v-text-field>
              <v-text-field
                v-model="editedReservation.end_date"
                label="End Date"
                type="datetime-local"
                required
              ></v-text-field>
              <v-select
                v-model="editedReservation.services"
                :items="globalStore.services"
                label="Services"
                item-title="name"
                item-value="id"
                multiple
                required
              ></v-select>
              <v-select
                v-model="editedReservation.employees"
                :items="globalStore.employees"
                label="Employees"
                item-title="name"
                item-value="id"
                multiple
                required
              ></v-select>
              <v-text-field
                v-model="editedReservation.comment"
                label="Comment"
                required
              ></v-text-field>
              <v-btn @click="saveReservation" :disabled="!valid">Save</v-btn>
            </v-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { h, nextTick, onMounted, ref } from "vue";
import { Qalendar } from "qalendar";
import { configInterface } from "qalendar/dist/src/typings/config.interface";
import {
  createReservation,
  getCompanyEmployees,
  getGlobalServices,
  getReservations,
  updateReservation,
} from "../services/api.service";
import { useCompanyStore } from "../stores/company.store";
import moment from "moment";
import {
  Reservation,
  ReservationStatus,
  UpdateReservationDTO,
} from "../types/reservation.type";
import CustomEvent from "../components/custom-event.vue";
import EventDialog from "../components/dialogs/event-dialog.vue";
import { useGlobalStore } from "../stores/global.store";

const config = ref<configInterface>({
  week: {
    startsOn: "monday" as any,
    nDays: 7,
    scrollToHour: 8,
  },
  month: {
    showTrailingAndLeadingDates: true,
  },
  eventDialog: {
    isCustom: true,
  },
  disableModes: ["month"],
  style: {
    fontFamily: "'Nunito', sans-serif",
    colorSchemes: {
      created: {
        color: "#fff",
        backgroundColor: "grey",
      },
      confirmed: {
        color: "#000",
        backgroundColor: "greenyellow",
      },
      rejected: {
        color: "#fff",
        backgroundColor: "red",
      },
      completed: {
        color: "#000",
        backgroundColor: "green",
      },
      archived: {
        color: "#fff",
        backgroundColor: "darkblue",
      },
    },
  },
  dayIntervals: {
    height: 80, // The height of each interval
  },
  locale: "uk-UA",
  defaultMode: "week",
  isSilent: false,
  showCurrentTime: true,
});
const events = ref<
  {
    title: string;
    with: string;
    time: { start: string; end: string };
    color?: string;
    colorScheme?: ReservationStatus;
    isEditable: boolean;
    id: number;
    isCustom: boolean;
    data: Reservation;
  }[]
>([]);

const loading = ref(false);
const hideSidebar = ref(true);
const startDate = ref(moment().utc().startOf("isoWeek").toISOString());
const endDate = ref(moment().utc().endOf("isoWeek").toISOString());
const reservations = ref<Reservation[]>([]);
const editedReservation = ref<Reservation | any>({
  id: 0,
  start_date: "",
  end_date: "",
  status: ReservationStatus.Draft,
  comment: "",
  services: [],
  users: [],
  employees: [],
  url: "",
});
const valid = ref(false);

const companyStore = useCompanyStore();
const globalStore = useGlobalStore();

onMounted(async () => {
  try {
    const services = await getGlobalServices([]);
    const employees = await getCompanyEmployees(
      companyStore.currentCompany?.id || 0
    );
    globalStore.services = services;
    globalStore.employees = employees;
    await loadEvents();
  } catch (error) {
    console.log(error);
  }
});

const loadEvents = async () => {
  loading.value = true;
  try {
    if (!companyStore.currentCompany) return;
    const response = await getReservations(
      companyStore.currentCompany.id.toString(),
      startDate.value,
      endDate.value
    );

    reservations.value = response;

    events.value = response.map((reservation) => ({
      title: reservation.services.map((service) => service.name).join(", "),
      with: reservation.users.map((user) => user.name).join(", "),
      time: {
        start: moment.utc(reservation.start_date).format("YYYY-MM-DD HH:mm"),
        end: moment.utc(reservation.end_date).format("YYYY-MM-DD HH:mm"),
      },
      colorScheme: reservation.status,
      isEditable: false,
      isCustom: true,
      id: reservation.id,
      data: reservation,
      employees: reservation.employees,
    }));
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
};

const periodUpdated = (period: { start: Date; end: Date }) => {
  startDate.value = moment.utc(period.start).startOf("day").toISOString();
  endDate.value = moment.utc(period.end).endOf("day").toISOString();
  loadEvents();
};

const saveReservation = async () => {
  if (editedReservation.value.id === 0) {
    await createEvent();
  } else {
    await updateEvent();
  }

  hideSidebar.value = !hideSidebar.value;
};

const createEvent = async () => {
  try {
    const newReservation = await createReservation({
      employee_ids: editedReservation.value.employees as number[],
      service_ids: editedReservation.value.services as number[],
      start_date: moment(editedReservation.value.start_date)
        .utc(true)
        .toISOString(),
      end_date: moment(editedReservation.value.end_date)
        .utc(true)
        .toISOString(),
    });

    resetEditedReservation();

    reservations.value.push(newReservation);

    events.value.push({
      title: newReservation.services.map((service) => service.name).join(", "),
      with: newReservation.users.map((user) => user.name).join(", "),
      time: {
        start: moment.utc(newReservation.start_date).format("YYYY-MM-DD HH:mm"),
        end: moment.utc(newReservation.end_date).format("YYYY-MM-DD HH:mm"),
      },
      colorScheme: newReservation.status,
      isEditable: false,
      isCustom: true,
      id: newReservation.id,
      data: newReservation,
    });

    events.value = [...events.value];
  } catch (error) {
    console.log(error);
  }
};

const updateEvent = async () => {
  try {
    const updatedReservation = await updateReservation({
      id: editedReservation.value.id,
      employee_ids: editedReservation.value.employees as number[],
      service_ids: editedReservation.value.services as number[],
      start_date: moment(editedReservation.value.start_date)
        .utc(true)
        .toISOString(),
      end_date: moment(editedReservation.value.end_date)
        .utc(true)
        .toISOString(),
    });

    reservations.value = reservations.value.map((reservation) =>
      reservation.id === updatedReservation.id
        ? updatedReservation
        : reservation
    );

    updateEventByReservation(updatedReservation);
    resetEditedReservation();
  } catch (error) {
    console.log(error);
  }
};

const editReservation = (reservation: Reservation) => {
  hideSidebar.value = !hideSidebar.value;
  editedReservation.value = {
    ...reservation,
    start_date: moment.utc(reservation.start_date).format("YYYY-MM-DDTHH:mm"),
    end_date: moment.utc(reservation.end_date).format("YYYY-MM-DDTHH:mm"),
  };
};

const updateEventByReservation = (updatedReservation: Reservation) => {
  events.value = events.value.map((event) =>
    event.id === updatedReservation.id
      ? {
          ...event,
          title: updatedReservation.services
            .map((service) => service.name)
            .join(", "),
          with: updatedReservation.users.map((user) => user.name).join(", "),
          time: {
            start: moment
              .utc(updatedReservation.start_date)
              .format("YYYY-MM-DD HH:mm"),
            end: moment
              .utc(updatedReservation.end_date)
              .format("YYYY-MM-DD HH:mm"),
          },
          colorScheme: updatedReservation.status,
          isEditable: false,
          isCustom: true,
          id: updatedReservation.id,
          data: updatedReservation,
          employees: updatedReservation.employees,
        }
      : event
  );
};

const resetEditedReservation = () => {
  editedReservation.value = {
    id: 0,
    start_date: "",
    end_date: "",
    status: ReservationStatus.Draft,
    comment: "",
    services: [],
    users: [],
    employees: [],
    url: "",
  };
};

const openCreateSidebar = (date: Date) => {
  hideSidebar.value = !hideSidebar.value;
  editedReservation.value = {
    ...editedReservation.value,
    start_date: moment.utc(date).format("YYYY-MM-DDTHH:00"),
    end_date: moment.utc(date).add(1, "hour").format("YYYY-MM-DDTHH:00"),
  };
};
</script>

<style lang="scss" scoped>
.schedules {
  height: calc(95vh - 126px);
  padding: 0 30px;
  display: flex;
  gap: 20px;
  flex-direction: column;

  @media screen and (min-width: 1200px) {
    justify-content: space-between;
    flex-direction: row;
  }

  .sidebar-hidden {
    display: none;

    .schedules__content {
      flex: 1;
    }
  }

  &__content {
    flex: 2;
  }

  &__calendar {
    height: 400px;
    overflow: scroll;
    padding: 25px;

    @media screen and (min-width: 1200px) {
      height: 100%;
    }
  }

  &__sidebar {
    flex: 1;
  }

  &__content,
  &__sidebar {
    width: 100%;
    border-radius: 20px;
    background: #fff;
  }

  &__sidebar-content {
    padding: 15px;
    height: 100%;
    overflow: hidden;
  }

  &__sidebar-form {
    overflow: scroll;
    height: calc(100% - 53px);
  }

  &__sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h3 {
      margin: 0;
    }
  }
}
</style>
