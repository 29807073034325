<template>
  <ColumnWithSidebar reversed>
    <template #sidebar>
      <SettingsNav @on-change="onTabChange" />
    </template>

    <template #content>
      <SettingsContent :current-tab-active="currentTabActive" />
    </template>
  </ColumnWithSidebar>
</template>
<script lang="ts" setup>
import { onMounted, ref } from "vue";
import SettingsNav from "../components/settings-nav.vue";
import SettingsContent from "../components/settings-content.vue";
import { useGlobalStore } from "../stores/global.store";
import { getGlobalServices } from "../services/api.service";
import ColumnWithSidebar from "../layouts/column-with-sidebar.vue";

const globalStore = useGlobalStore();

onMounted(async () => {
  try {
    const services = await getGlobalServices([]);

    globalStore.services = services;
  } catch (error) {
    console.log(error);
  }
});

const currentTabActive = ref<number>(1);

const onTabChange = (e: number) => {
  currentTabActive.value = e;
};
</script>

<style lang="scss" scoped></style>
