<template>
  <header class="header">
    <div class="header__logo">
      <h1>Booqimate</h1>
    </div>

    <div class="header__btns">
      <VBtn
        icon="mdi-menu"
        variant="text"
        class="d-md-none"
        @click="sidebar = true"
      />

      <div class="d-none d-md-flex">
        <RouterLink :to="Routes.Admin" v-slot="{ navigate }">
          <VBtn
            icon="mdi-home"
            :ripple="false"
            variant="text"
            @click="navigate"
          />
        </RouterLink>

        <RouterLink :to="Routes.AdminCustomers" v-slot="{ navigate }">
          <VBtn
            :ripple="false"
            icon="mdi-account-group-outline"
            variant="text"
            @click="navigate"
          />
        </RouterLink>

        <RouterLink :to="Routes.AdminSchedule" v-slot="{ navigate }">
          <VBtn
            :ripple="false"
            icon="mdi-calendar-clock-outline"
            variant="text"
            @click="navigate"
          />
        </RouterLink>

        <RouterLink :to="Routes.AdminSettings" v-slot="{ navigate }">
          <VBtn
            icon="mdi-cog-outline"
            :ripple="false"
            variant="text"
            @click="navigate"
          />
        </RouterLink>

        <VMenu>
          <template #activator="{ props }">
            <VBtn
              icon="mdi-home-switch"
              :ripple="false"
              v-bind="props"
              variant="text"
            ></VBtn>
          </template>

          <VList
            @click:select="onCompanyChange"
            :selected="[companyStore.currentCompany]"
          >
            <VListItem
              v-for="item in companyStore.userCompanies"
              :key="item.id"
              :value="item"
            >
              <VListItemTitle>
                {{ item.title }}
              </VListItemTitle>
            </VListItem>
          </VList>
        </VMenu>

        <VBtn
          icon="mdi-exit-to-app"
          variant="text"
          class="header__btns-end"
          @click="logoutApp"
        />
      </div>
    </div>
    <VNavigationDrawer v-model="sidebar" temporary location="right">
      <VList>
        <VListItem @click="router.push(Routes.Admin)">
          <template #prepend><VIcon>mdi-home</VIcon> </template>
          <VListItemTitle>Home</VListItemTitle>
        </VListItem>

        <VListItem @click="router.push(Routes.AdminCustomers)">
          <template #prepend>
            <VIcon>mdi-account-group-outline</VIcon>
          </template>
          <VListItemTitle>Customers</VListItemTitle>
        </VListItem>

        <VListItem @click="router.push(Routes.AdminSchedule)">
          <template #prepend>
            <VIcon>mdi-calendar-clock-outline</VIcon>
          </template>
          <VListItemTitle>Schedule</VListItemTitle>
        </VListItem>

        <VListItem @click="router.push(Routes.AdminSettings)">
          <template #prepend>
            <VIcon>mdi-cog-outline</VIcon>
          </template>
          <VListItemTitle>Settings</VListItemTitle>
        </VListItem>
        <VMenu>
          <template #activator="{ props }">
            <VListItem v-bind="props">
              <template #prepend>
                <VIcon>mdi-home-switch</VIcon>
              </template>
              <VListItemTitle>Company</VListItemTitle>
            </VListItem>
          </template>

          <VList
            @click:select="onCompanyChange"
            :selected="[companyStore.currentCompany]"
          >
            <VListItem
              v-for="item in companyStore.userCompanies"
              :key="item.id"
              :value="item"
            >
              <VListItemTitle>
                {{ item.title }}
              </VListItemTitle>
            </VListItem>
          </VList>
        </VMenu>

        <VListItem @click="logoutApp">
          <template #prepend>
            <VIcon>mdi-exit-to-app</VIcon>
          </template>
          <VListItemTitle>Logout</VListItemTitle>
        </VListItem>
      </VList>
    </VNavigationDrawer>
  </header>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import {
  getCompanyEmployees,
  getCompanyServices,
  getCurrentUser,
  getUserCompanies,
  logout,
} from "../services/api.service";
import { useCompanyStore } from "../stores/company.store";
import { Routes } from "../types/enums/routes.enum";
import { Company, CurrentCompany } from "../types/company.type";
import { onMounted, ref } from "vue";
import { useUserStore } from "../stores/user.store";

const companyStore = useCompanyStore();
const userStore = useUserStore();
const router = useRouter();

const sidebar = ref(false);

onMounted(async () => {
  const [companies, user] = await Promise.all([
    getUserCompanies(),
    getCurrentUser(),
  ]);

  if (companies.length) {
    companyStore.userCompanies = companies;
    userStore.user = user;
    userStore.subscription = user.subscription;
    const currentCompany = companies.find(
      (company) => company.id == companyStore.currentCompany?.id
    );
    if (currentCompany) {
      companyStore.currentCompany = currentCompany as CurrentCompany;
      const employees = await getCompanyEmployees(
        companyStore.currentCompany?.id!
      );
      companyStore.employees = employees;
    }
  } else {
    await logout();
  }
});

const logoutApp = async () => {
  await logout();
  companyStore.$reset();
  userStore.$reset();
  router.push(Routes.Main);
};

const onCompanyChange = async (event: {
  id: unknown;
  value: boolean;
  path: unknown[];
}) => {
  const company = event.id as Company;
  const [employees, services] = await Promise.all([
    getCompanyEmployees(company.id!),
    getCompanyServices(company.id!),
  ]);

  companyStore.$patch({
    currentCompany: company,
    employees: employees,
    services: services,
  });
};
</script>

<style lang="scss" scoped>
.header {
  border-radius: 0px 0px 20px 20px;
  background: #fff;
  width: 100%;
  // height: 70px;
  padding: 8px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  &__logo {
    max-width: 300px;

    h1 {
      color: #504747;

      text-align: center;
      font-family: Montserrat;
      font-size: 25px;
      font-style: normal;
      font-weight: 700;
    }
  }

  &__btns {
    display: flex;
    column-gap: 36px;

    :deep(.v-icon) {
      font-size: 30px;
      color: black;
    }

    &-end {
      margin-left: 30px;
    }
  }
}
</style>
