import {
  WeekObject,
  WorkingHoursSchedule,
} from "../types/working-hours-schedule.type";

function isUndefined(value: any) {
  return value === undefined;
}

function isNull(value: any) {
  return value === null;
}

function isBoolean(value: any) {
  return typeof value === "boolean";
}

function isObject(value: any) {
  return value === Object(value);
}

function isArray(value: any) {
  return Array.isArray(value);
}

function isDate(value: any) {
  return value instanceof Date;
}

function isBlob(value: any, isReactNative: any) {
  return value instanceof Blob;
}

function isFile(value: any, isReactNative: any) {
  return value instanceof File;
}

function initCfg(value: any) {
  return isUndefined(value) ? false : value;
}

export const objectToFormData = (obj: any, cfg?: any, fd?: any, pre?: any) => {
  cfg = cfg || {};
  fd = fd || new FormData();

  cfg.indices = initCfg(cfg.indices);
  cfg.nullsAsUndefineds = initCfg(cfg.nullsAsUndefineds);
  cfg.booleansAsIntegers = initCfg(cfg.booleansAsIntegers);
  cfg.allowEmptyArrays = initCfg(cfg.allowEmptyArrays);
  cfg.noAttributesWithArrayNotation = initCfg(
    cfg.noAttributesWithArrayNotation
  );
  cfg.noFilesWithArrayNotation = initCfg(cfg.noFilesWithArrayNotation);
  cfg.dotsForObjectNotation = initCfg(cfg.dotsForObjectNotation);

  const isReactNative = typeof fd.getParts === "function";

  if (isUndefined(obj)) {
    return fd;
  } else if (isNull(obj)) {
    if (!cfg.nullsAsUndefineds) {
      fd.append(pre, "");
    }
  } else if (isBoolean(obj)) {
    if (cfg.booleansAsIntegers) {
      fd.append(pre, obj ? 1 : 0);
    } else {
      fd.append(pre, obj);
    }
  } else if (isArray(obj)) {
    if (obj.length) {
      obj.forEach((value: any, index: any) => {
        let key = pre + "[" + (cfg.indices ? index : "") + "]";
        if (
          cfg.noAttributesWithArrayNotation ||
          (cfg.noFilesWithArrayNotation && isFile(value, isReactNative))
        ) {
          key = pre;
        }

        objectToFormData(value, cfg, fd, key);
      });
    } else if (cfg.allowEmptyArrays) {
      fd.append(cfg.noAttributesWithArrayNotation ? pre : pre + "[]", "");
    }
  } else if (isDate(obj)) {
    fd.append(pre, obj.toISOString());
  } else if (isObject(obj) && !isBlob(obj, isReactNative)) {
    Object.keys(obj).forEach((prop) => {
      const value = obj[prop];

      if (isArray(value)) {
        while (prop.length > 2 && prop.lastIndexOf("[]") === prop.length - 2) {
          prop = prop.substring(0, prop.length - 2);
        }
      }

      const key = pre
        ? cfg.dotsForObjectNotation
          ? pre + "." + prop
          : pre + "[" + prop + "]"
        : prop;

      objectToFormData(value, cfg, fd, key);
    });
  } else {
    fd.append(pre, obj);
  }

  return fd;
};

export const addZero = (num: number): string => {
  if (num <= 9 && num >= 0) {
    return `0${num}`;
  }

  return `${num}`;
};

export function convertWeekObjectToWorkingHoursSchedule(
  weekObject: WeekObject
): WorkingHoursSchedule[] {
  let workingHoursSchedule: WorkingHoursSchedule[] = [];

  for (const [key, { enabled, value, weekday }] of Object.entries(weekObject)) {
    const opens_at = value[0]
      ? `${addZero(value[0].hours)}:${addZero(value[0].minutes)}`
      : undefined;
    const closes_at = value[1]
      ? `${addZero(value[1].hours)}:${addZero(value[1].minutes)}`
      : undefined;

    workingHoursSchedule[weekday] = {
      _destroy: enabled ? 0 : 1,
      opens_at,
      weekday,
      closes_at,
      id: weekObject[key].id,
    };
  }

  return workingHoursSchedule;
}

export function revertFromWorkingHoursScheduleToWeekObject(
  workingHoursSchedule?: WorkingHoursSchedule[]
): WeekObject {
  const weekObjectTemplate = {
    monday: {
      enabled: false,
      value: [],
      label: "Понеділок",
      weekday: 1,
      id: "",
    },
    tuesday: {
      enabled: false,
      value: [],
      label: "Вівторок",
      weekday: 2,
      id: "",
    },
    wednesday: {
      enabled: false,
      value: [],
      label: "Середа",
      weekday: 3,
      id: "",
    },
    thursday: {
      enabled: false,
      value: [],
      label: "Четверг",
      weekday: 4,
      id: "",
    },
    friday: {
      enabled: false,
      value: [],
      label: "П'ятниця",
      weekday: 5,
      id: "",
    },
    saturday: {
      enabled: false,
      value: [],
      label: "Субота",
      weekday: 6,
      id: "",
    },
    sunday: {
      enabled: false,
      value: [],
      label: "Неділя",
      weekday: 0,
      id: "",
    },
  };

  const weekdayMap: Record<string, string> = {
    1: "monday",
    2: "tuesday",
    3: "wednesday",
    4: "thursday",
    5: "friday",
    6: "saturday",
    0: "sunday",
  };

  const weekObject = JSON.parse(JSON.stringify(weekObjectTemplate));
  if (!workingHoursSchedule) {
    return weekObject;
  }

  workingHoursSchedule.forEach(
    ({ id, _destroy, opens_at, closes_at }, weekday) => {
      {
        const dayKey = weekdayMap[weekday];

        weekObject[dayKey].enabled = _destroy === 0;

        if (opens_at) {
          const [opensHours, opensMinutes] = opens_at.split(":").map(Number);
          weekObject[dayKey].value[0] = {
            hours: opensHours,
            minutes: opensMinutes,
          };
        }

        if (id) {
          weekObject[dayKey].id = id;
        }

        if (closes_at) {
          const [closesHours, closesMinutes] = closes_at.split(":").map(Number);
          weekObject[dayKey].value[1] = {
            hours: closesHours,
            minutes: closesMinutes,
          };
        }
      }
    }
  );

  return weekObject;
}

export function humanizeDate(date?: string) {
  if (!date) return "";
  const d = new Date(date);
  return `${addZero(d.getDate())}/${addZero(
    d.getMonth()
  )}/${d.getFullYear()}  ${addZero(d.getHours())}:${addZero(d.getMinutes())}`;
}
