<template>
  <div class="unconfirmed-list">
    <h2>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_2845_1739"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_2845_1739)">
          <path
            d="M3.5 19.75V18.25H15V19.75H3.5ZM16.0014 12.75C14.6876 12.75 13.5673 12.287 12.6404 11.361C11.7135 10.435 11.25 9.31517 11.25 8.0014C11.25 6.68763 11.713 5.56729 12.639 4.64038C13.5649 3.71346 14.6848 3.25 15.9986 3.25C17.3123 3.25 18.4327 3.71298 19.3596 4.63895C20.2865 5.56492 20.75 6.68478 20.75 7.99855C20.75 9.31232 20.287 10.4327 19.361 11.3596C18.435 12.2865 17.3152 12.75 16.0014 12.75ZM3.5 11.75V10.25H9.33845C9.42947 10.5269 9.53234 10.7888 9.64708 11.0356C9.76183 11.2823 9.89868 11.5205 10.0577 11.75H3.5ZM3.5 15.75V14.25H12.8327C13.1583 14.4256 13.5026 14.575 13.8654 14.6981C14.2282 14.8211 14.6064 14.9096 15 14.9635V15.75H3.5ZM15.5577 8.88458H16.4423V5.09613H15.5577V8.88458ZM16 10.9423C16.1333 10.9423 16.25 10.8923 16.35 10.7923C16.45 10.6923 16.5 10.5756 16.5 10.4423C16.5 10.3089 16.45 10.1923 16.35 10.0923C16.25 9.99228 16.1333 9.94228 16 9.94228C15.8666 9.94228 15.75 9.99228 15.65 10.0923C15.55 10.1923 15.5 10.3089 15.5 10.4423C15.5 10.5756 15.55 10.6923 15.65 10.7923C15.75 10.8923 15.8666 10.9423 16 10.9423Z"
            fill="#1C1B1F"
          />
        </g>
      </svg>
      Непідтверджені візити
    </h2>
    <VContainer v-if="loading">
      <VRow justify="center">
        <VProgressCircular indeterminate color="primary" />
      </VRow>
    </VContainer>

    <v-snackbar v-model="snackbar" timeout="2500">
      {{ snackbarText }}

      <template v-slot:actions>
        <v-btn color="pink" variant="text" @click="snackbar = false">
          Закрити
        </v-btn>
      </template>
    </v-snackbar>

    <div class="unconfirmed-list__list" v-if="reservations.length">
      <UnconfirmedItem
        v-for="reservation in reservations"
        :key="reservation.id"
        :title="getServiceName(reservation)"
        :description="getReservationDescription(reservation)"
        :employees="reservation.employees"
        :reservation="reservation"
        @update-reservation="updateReservation"
        @confirm-reservation="confirmReservation"
      >
      </UnconfirmedItem>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted } from "vue";
import UnconfirmedItem from "./unconfirmed-item.vue";
import { ref } from "vue";
import { Reservation } from "../types/reservation.type";
import { getCompanyWaitList } from "../services/api.service";
import moment from "moment";
import { useCompanyStore } from "../stores/company.store";
import { watch } from "vue";
import { storeToRefs } from "pinia";

const emit = defineEmits(["unconfirmed-reservations-count"]);

const loading = ref(false);

const reservations = ref<Reservation[]>([]);

const snackbar = ref(false);
const snackbarText = ref("");

const companyStore = useCompanyStore();

const { currentCompany } = storeToRefs(companyStore);

const getServiceName = (reservation: Reservation) =>
  reservation.services.length ? reservation.services[0].name : "Без послуги";

const getReservationDescription = (reservation: Reservation) => {
  try {
    const date = moment(reservation.start_date);

    let description = `${date.utc().format("D MMMM YYр, HH:mm")}, ${
      reservation.services_sum
    } грн`;
    return description;
  } catch (error) {
    console.log(error);
  }
  return "";
};

watch(currentCompany, (newValue, oldValue) => {
  if (newValue?.id != oldValue?.id) {
    loadUncofirmedReservations();
  }
});

onMounted(() => {
  loadUncofirmedReservations();
});

const loadUncofirmedReservations = async () => {
  loading.value = true;
  reservations.value = [];
  try {
    const res = await getCompanyWaitList();
    reservations.value.push(...res);
  } catch (error) {
    console.log(error);
  }
  emit("unconfirmed-reservations-count", reservations.value.length);
  loading.value = false;
};

const updateReservation = (updatedReservation: Reservation) => {
  const reservationIndex = reservations.value.findIndex(
    (reserv) => reserv.id == updatedReservation.id
  );
  if (reservationIndex != -1)
    reservations.value[reservationIndex] = updatedReservation;

  snackbarText.value = "Візит оновлено.";
  snackbar.value = true;
};

const confirmReservation = async (reservation: Reservation) => {
  let arrayCopy = [...reservations.value];
  const reservationIndex = arrayCopy.findIndex(
    (reserv) => reserv.id == reservation.id
  );
  if (reservationIndex != -1) arrayCopy.splice(reservationIndex, 1);

  reservations.value = arrayCopy;

  snackbarText.value = "Візит підтверджено.";
  snackbar.value = true;
};
</script>

<style lang="scss" scoped>
.unconfirmed-list {
  padding: 24px 20px;

  &__list {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  h2 {
    color: #2e2a2a;
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }
}
</style>
