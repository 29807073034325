<template>
  <div
    class="schedules__event-dialog event-dialog"
    v-if="eventProps.eventDialogData && eventProps.eventDialogData.title"
  >
    <div class="event-dialog__header">
      <div class="event-dialog__title">
        <span class="mdi mdi-calendar"></span>
        {{ eventProps.eventDialogData.title }}
      </div>
      <div class="event-dialog__actions">
        <span
          class="mdi mdi-pencil"
          @click="edit(eventProps.eventDialogData.data)"
        ></span>
        <span class="mdi mdi-delete"></span>
      </div>
    </div>
    <div class="event-dialog__content">
      <div class="event-dialog__info">
        <div class="event-dialog__info-item">
          <span class="mdi mdi-clock"></span>
          <span
            >{{ eventProps.eventDialogData.time.start }} -
            {{ eventProps.eventDialogData.time.end }}</span
          >
        </div>
        <div class="event-dialog__info-item">
          <span class="mdi mdi-account"></span>
          <span>{{ eventProps.eventDialogData.with }}</span>
        </div>
        <div class="event-dialog__info-item">
          <span class="mdi mdi-phone"></span>
          <span>{{
            eventProps.eventDialogData.data.users
              .map((user: any) => user.phone_number)
              .join(" ,")
          }}</span>
        </div>
      </div>
      <div class="event-dialog__description">
        <span class="mdi mdi-text"></span>
        <span>{{ eventProps.eventDialogData.data.comment }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  eventProps: {
    eventDialogData: any;
    closeEventDialog: () => void;
  };
}>();

const emit = defineEmits(["edit"]);

const edit = (data: any) => {
  emit("edit", data);
  props.eventProps?.closeEventDialog();
};
</script>
<style lang="scss" scoped>
.event-dialog {
  padding: 10px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__actions {
    display: flex;
    gap: 10px;

    span {
      cursor: pointer;
      color: gray;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__info-item {
    display: flex;
    gap: 5px;
  }

  &__description {
    display: flex;
    gap: 5px;
  }

  .mdi {
    font-size: 20px;
  }
}
</style>
