<template>
  <VContainer class="schedule">
    <VRow v-if="companyStore.currentCompany">
      <VCol cols="12">
        <VSwitch
          v-model="companyStore.currentCompany.custom_schedule"
          label="Вільний графік"
          @change="saveSchedule"
          inset
          color="success"
        />
      </VCol>
      <VCol cols="12">
        <VRow v-if="!currentCompany!.custom_schedule">
          <VCol cols="12">
            <ScheduleList
              @update:weekobject="onWeekObjectUpdate"
              :schedules="currentCompany!.schedules_attributes"
              is-employee
            />
          </VCol>
          <VBtn @click="saveSchedule">Зберегти</VBtn>
        </VRow>
        <div v-else>
          <div class="">
            <div class="table-view__table">
              <div class="schedule__add">
                <form ref="form" @submit="addSchedule">
                  <VueDatePicker
                    locale="uk"
                    hours-increment="1"
                    minutes-increment="5"
                    minutes-grid-increment="5"
                    class="schedule-item__timepicker"
                    :start-time="[
                      { hours: 0, minutes: 0 },
                      { hours: 23, minutes: 0 },
                    ]"
                    required
                    teleport="body"
                    :min-date="new Date()"
                    v-model="startDate"
                  />
                  <VueDatePicker
                    locale="uk"
                    hours-increment="1"
                    minutes-increment="5"
                    minutes-grid-increment="5"
                    class="schedule-item__timepicker"
                    :start-time="[
                      { hours: 0, minutes: 0 },
                      { hours: 23, minutes: 0 },
                    ]"
                    required
                    teleport="body"
                    :min-date="startDate"
                    v-model="endDate"
                  />
                  <VBtn type="submit">Додати графік</VBtn>
                </form>
              </div>
              <div class="table-view__body">
                <div class="table-view__header">
                  <div class="table-view__name">Початок</div>
                  <div class="table-view__name">Кінець</div>
                  <div></div>
                </div>
                <template
                  v-for="schedule in companyStore.currentCompany
                    .schedules_attributes"
                >
                  <div class="table-view__name">
                    {{ humanizeDate(schedule.custom_available_start) }}
                  </div>
                  <div>{{ humanizeDate(schedule.custom_available_end) }}</div>
                  <div class="table-view__actions">
                    <VBtn
                      color="red"
                      icon
                      @click="openDeleteDialog(schedule.id!)"
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </VBtn>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import ScheduleList from "../schedule-list.vue";
import { useCompanyStore } from "@/main/stores/company.store";
import { WorkingHoursSchedule } from "@/main/types/working-hours-schedule.type";
import { updateCompany } from "@/main/services/api.service";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { humanizeDate } from "@/main/helpers/utils.helper";

const companyStore = useCompanyStore();

const deleteDialog = ref(false);
const customScheduleId = ref<number>();
const startDate = ref<string>();
const endDate = ref<string>();
const form = ref(null);

const currentCompany = computed(() => companyStore.currentCompany);

const addSchedule = async (e: Event) => {
  e.preventDefault();
  if (!currentCompany.value) return;

  await saveSchedule({
    _destroy: 0,
    custom_available_start: startDate.value,
    custom_available_end: endDate.value,
  });
};

const saveSchedule = async (schedules_attributes?: WorkingHoursSchedule) => {
  if (!currentCompany.value) return;

  try {
    const schedules = schedules_attributes
      ? [
          ...(currentCompany.value.schedules_attributes || []),
          schedules_attributes,
        ]
      : currentCompany.value.schedules_attributes;

    const company = await updateCompany(currentCompany.value.id, {
      custom_schedule: currentCompany.value.custom_schedule,
      schedules_attributes: schedules,
    });

    companyStore.$patch({
      currentCompany: company,
    });
  } catch (error) {
    console.error(error);
  }
  startDate.value = "";
  endDate.value = "";
};

const onWeekObjectUpdate = (workingHours: WorkingHoursSchedule[]) => {
  console.log(workingHours, "workingHours");
  companyStore.$patch({
    currentCompany: {
      ...currentCompany.value,
      schedules_attributes: workingHours,
    },
  });
};

const openDeleteDialog = (id: number) => {
  deleteDialog.value = true;
  customScheduleId.value = id;
};
</script>

<style lang="scss" scoped>
.schedule {
  &__add {
    form {
      display: flex;
      column-gap: 30px;
      padding: 10px 0;
    }
  }

  :deep(.v-input__details) {
    display: none;
  }
}
.table-view {
  h3 {
    margin-bottom: 0;
  }

  &__header {
    grid-template-columns: repeat(3, 1fr);
  }

  &__body {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
