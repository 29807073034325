<template>
  <div class="settings-nav">
    <VList class="settings-nav__list">
      <VListItem
        @click="onItemClick(1)"
        :active="active == 1"
        active-class="settings-nav__item-active"
        class="settings-nav__item"
        rounded="15"
      >
        <template #title>
          <h4>Головна</h4>
        </template>
        <template #subtitle>
          <p>Додавайте фото та опис Вашої компанії</p>
        </template>
      </VListItem>
      <VListItem
        @click="onItemClick(2)"
        :active="active == 2"
        active-class="settings-nav__item-active"
        class="settings-nav__item"
        rounded="15"
      >
        <template #title>
          <h4>Послуги</h4>
        </template>
        <template #subtitle>
          <p>Додавайте чи прибирайте послуги, змінюйте ціни чи опис</p>
        </template>
      </VListItem>
      <VListItem
        @click="onItemClick(3)"
        :active="active == 3"
        active-class="settings-nav__item-active"
        class="settings-nav__item"
        rounded="15"
      >
        <template #title>
          <h4>Команда</h4>
        </template>
        <template #subtitle>
          <p>Керуйте командою, додавайте нових працівників</p>
        </template>
      </VListItem>
      <VListItem
        @click="onItemClick(4)"
        :active="active == 4"
        active-class="settings-nav__item-active"
        class="settings-nav__item"
        rounded="15"
      >
        <template #title>
          <h4>Графік</h4>
        </template>
        <template #subtitle>
          <p>Керуйте графіком роботи компанії чи команди</p>
        </template>
      </VListItem>
      <VListItem
        @click="onItemClick(5)"
        :active="active == 5"
        active-class="settings-nav__item-active"
        class="settings-nav__item"
        rounded="15"
      >
        <template #title>
          <h4>Аккаунт</h4>
        </template>
        <template #subtitle>
          <p>Керуйте вашим обліковим персональним записом</p>
        </template>
      </VListItem>
    </VList>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
const emit = defineEmits<{
  (e: "onChange", value: number): void;
}>();
const active = ref(1);
const onItemClick = (index: number) => {
  active.value = index;

  emit("onChange", active.value);
};
</script>

<style lang="scss" scoped>
.settings-nav {
  padding: 32px;
  height: 100%;

  &__item {
    background-color: #e7eaee;
    padding: 16px;
    cursor: pointer;

    h4 {
      color: #403838;
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 16px;
    }

    p {
      margin-bottom: 0;
      font-family: "Inter", sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      color: #403838;
    }

    &-active {
      background: #403838;

      h4,
      p {
        color: white;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    overflow: scroll;
    height: 100%;
  }

  .rounded-15 {
    border-radius: 15px;
  }
}
</style>
