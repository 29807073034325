import {
  axiosApiInstance,
  axiosApiInstanceNoAuth,
} from "../helpers/axios.helper";
import {
  LocalStorageKeys,
  localForageStorage,
} from "../helpers/storage.helper";
import { objectToFormData } from "../helpers/utils.helper";
import { useCompanyStore } from "../stores/company.store";
import { AuthTokens } from "../types/auth-tokens.type";
import { Category } from "../types/category.type";
import {
  Company,
  CompanyCustomer,
  CreateCompanyDTO,
  UpdateCompanyDTO,
} from "../types/company.type";
import {
  CreateEmployeeDTO,
  Employee,
  UpdateEmployeeDTO,
} from "../types/employee.type";
import {
  CreateReservationDTO,
  Reservation,
  UpdateReservationDTO,
} from "../types/reservation.type";
import {
  CompanyService,
  CreateCompanyServiceDTO,
  Service,
} from "../types/service.type";
import { User } from "../types/user.type";

export type SignUpDTO = {
  username: string;
  password: string;
  password_confirmation: string;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
};

export const signIn = async (
  login: string,
  password: string
): Promise<boolean> => {
  const { data } = await axiosApiInstanceNoAuth.post<AuthTokens>("/sign_in", {
    login,
    password,
  });

  const tokens = await localForageStorage.setItem(LocalStorageKeys.Tokens, {
    ...data,
    refreshedAt: new Date().getTime(),
  });

  if (tokens) return true;

  return false;
};

export const signUp = async (dataDTO: SignUpDTO) => {
  const { data } = await axiosApiInstanceNoAuth.post<AuthTokens>(
    "/sign_up",
    dataDTO
  );

  const tokens = await localForageStorage.setItem(LocalStorageKeys.Tokens, {
    ...data,
    refreshedAt: new Date().getTime(),
  });

  console.log(tokens);
};

export const refreshToken = async (
  refresh_token: string
): Promise<AuthTokens> => {
  const { data } = await axiosApiInstanceNoAuth.post<AuthTokens>(
    "/refresh",
    null,
    {
      headers: {
        Authorization: `Bearer ${refresh_token}`,
      },
    }
  );

  const tokens = await localForageStorage.setItem(LocalStorageKeys.Tokens, {
    ...data,
    refreshedAt: new Date().getTime(),
  });

  return tokens;
};

export const logout = async (): Promise<boolean> => {
  const tokens = await localForageStorage.getItem<AuthTokens>(
    LocalStorageKeys.Tokens
  );
  try {
    if (tokens) {
      await axiosApiInstanceNoAuth.post("/revoke", null, {
        headers: {
          Authorization: `Bearer ${tokens.access_token}`,
        },
      });

      await axiosApiInstanceNoAuth.post("/revoke", null, {
        headers: {
          Authorization: `Bearer ${tokens.refresh_token}`,
        },
      });
    }
  } catch (error) {
    console.log(error);
  } finally {
    await localForageStorage.removeItem(LocalStorageKeys.Tokens);
    return true;
  }
};

export const getCategories = async (): Promise<Category[]> => {
  const { data } = await axiosApiInstanceNoAuth.get<Category[]>(
    "/int/categories"
  );

  return data;
};

export const createCompany = async (
  params: CreateCompanyDTO
): Promise<Company> => {
  const { data } = await axiosApiInstance.post<Company>("/int/companies", {
    company: params,
  });

  return data;
};

export const getCompanyById = async (companyId: number): Promise<Company> => {
  const { data } = await axiosApiInstance.get<Company>(
    `/int/companies/${companyId}`
  );

  return data;
};

export const getUserCompanies = async (): Promise<Company[]> => {
  const {
    data: { companies },
  } = await axiosApiInstance.get<{ companies: Company[] }>("/int/companies");

  return companies;
};

export const updateCompany = async (
  companyId: number,
  params: UpdateCompanyDTO
): Promise<Company> => {
  console.log(params);
  const formData = objectToFormData(
    {
      company: params,
    },
    {
      indices: true,
    }
  );

  const { data } = await axiosApiInstance.patch<Company>(
    `/int/companies/${companyId}`,
    formData
  );

  return data;
};

export const getCompanyCustomers = async (
  companyId: number,
  per_page: number = 10,
  page: number = 1
): Promise<{
  customers: CompanyCustomer[];
  pages: {
    current: string;
    totalPages: number;
    itemsLength: number;
  };
}> => {
  const {
    data: { customers, links },
  } = await axiosApiInstance.get<{
    customers: CompanyCustomer[];
    links: {
      self: string;
      total_pages: number;
      items_length: number;
    };
  }>(`/int/companies/${companyId}/customers`, {
    params: {
      per_page,
      page,
    },
  });

  return {
    customers,
    pages: {
      current: `${links.self}`,
      totalPages: links.total_pages,
      itemsLength: links.items_length,
    },
  };
};

export const getCompanyEmployees = async (companyId: number) => {
  const {
    data: { employees },
  } = await axiosApiInstance.get<{
    employees: Employee[];
  }>(`/int/companies/${companyId}/employees`);

  return employees;
};

export const createCompanyEmployee = async (
  companyId: number,
  params: CreateEmployeeDTO
): Promise<Employee> => {
  const formData: FormData = objectToFormData(
    {
      employee: params,
    },
    {
      indices: true,
    }
  );

  const { data } = await axiosApiInstance.post<Employee>(
    `/int/companies/${companyId}/employees`,
    formData
  );

  return data;
};

export const updateCompanyEmployee = async (
  companyId: number,
  params: UpdateEmployeeDTO
): Promise<Employee> => {
  if (typeof params.avatar == "string") {
    delete params.avatar;
  }

  const formData: FormData = objectToFormData(
    {
      employee: params,
    },
    {
      indices: true,
    }
  );

  const { data } = await axiosApiInstance.patch<Employee>(
    `/int/companies/${companyId}/employees/${params.id}`,
    formData
  );

  return data;
};

export const deleteCompanyEmployee = async (
  companyId: number,
  employeeId: number
): Promise<Employee> => {
  const { data } = await axiosApiInstance.delete<Employee>(
    `/int/companies/${companyId}/employees/${employeeId}`
  );

  return data;
};

export const createCompanyService = async (
  companyId: number,
  params: CreateCompanyServiceDTO
): Promise<CompanyService> => {
  const { data } = await axiosApiInstance.post<CompanyService>(
    `/int/companies/${companyId}/services`,
    {
      company_service: params,
    }
  );

  return data;
};

export const updateCompanyService = async (
  companyId: number,
  params: CreateCompanyServiceDTO
): Promise<CompanyService> => {
  const { data } = await axiosApiInstance.patch<CompanyService>(
    `/int/companies/${companyId}/services/${params.id}`,
    {
      company_service: params,
    }
  );

  return data;
};

export const deleteCompanyService = async (
  companyId: number,
  serviceId: number
): Promise<void> => {
  await axiosApiInstance.delete(
    `/int/companies/${companyId}/services/${serviceId}`
  );
};

export const getCompanyServices = async (
  companyId: number
): Promise<CompanyService[]> => {
  const {
    data: { services },
  } = await axiosApiInstance.get<{ services: CompanyService[] }>(
    `/int/companies/${companyId}/services`
  );

  return services;
};

export const getReservations = async (
  companyId: string,
  startDate: string,
  endDate?: string
): Promise<Reservation[]> => {
  const {
    data: { reservations },
  } = await axiosApiInstance.get<{ reservations: Reservation[] }>(
    `/int/companies/${companyId}/reservations`,
    {
      params: {
        start_date: startDate,
        ...(endDate ? { end_date: endDate } : {}),
      },
    }
  );

  return reservations;
};

export const getCompanyWaitList = async (): Promise<Reservation[]> => {
  const { currentCompany } = useCompanyStore();
  const {
    data: { reservations },
  } = await axiosApiInstance.get<{ reservations: Reservation[] }>(
    `/int/companies/${currentCompany?.id}/wait-list`
  );

  return reservations;
};

export const createReservation = async (
  params: CreateReservationDTO
): Promise<Reservation> => {
  const { currentCompany } = useCompanyStore();
  const { data } = await axiosApiInstance.post<Reservation>(
    `/int/companies/${currentCompany?.id}/reservations`,
    {
      reservation: params,
    }
  );

  return data;
};

export const updateReservation = async (
  params: UpdateReservationDTO
): Promise<Reservation> => {
  const { currentCompany } = useCompanyStore();
  const { data } = await axiosApiInstance.patch<Reservation>(
    `/int/companies/${currentCompany?.id}/reservations/${params.id}`,
    {
      reservation: params,
    }
  );

  return data;
};

export const confirmReservation = async (id: number): Promise<Reservation> => {
  const { currentCompany } = useCompanyStore();
  const { data } = await axiosApiInstance.patch<Reservation>(
    `/int/companies/${currentCompany?.id}/reservations/${id}/confirm`
  );

  return data;
};

export const getGlobalServices = async (
  categoryIds: number[]
): Promise<Service[]> => {
  const {
    data: { services },
  } = await axiosApiInstance.get<{ services: Service[] }>("/int/services", {
    params: {
      category_ids: categoryIds,
    },
  });

  return services;
};

export const getCurrentUser = async (): Promise<User> => {
  const { data } = await axiosApiInstance.get<User>("/int/users/me");

  return data;
};

export const updateCurrentUser = async (
  params: Partial<User>
): Promise<User> => {
  const { data } = await axiosApiInstance.patch<User>("/int/users/me", {
    user: params,
  });

  return data;
};

export const updateCurrentUserPassword = async (
  oldPassword: string,
  newPassword: string,
  newPasswordConfirmation: string
): Promise<User> => {
  const { data } = await axiosApiInstance.patch<User>("/int/users/me", {
    user: {
      old_password: oldPassword,
      password: newPassword,
      password_confirmation: newPasswordConfirmation,
    },
  });

  return data;
};
