<template>
  <VDialog
    :model-value="opened"
    @update:model-value="closeDialog"
    width="1000"
    eager
  >
    <VForm validate-on="submit lazy" @submit.prevent="closeDialog(false)">
      <VCard>
        <VCardTitle>
          <span class="text-h5">{{
            currentEmployee.id
              ? "Змінити данні працівника"
              : "Додати працівника"
          }}</span>
        </VCardTitle>
        <VCardText v-if="currentEmployee">
          <VContainer>
            <VRow>
              <VCol cols="4">
                <EmployeeForm
                  v-model:name="currentEmployee.name"
                  v-model:username="currentEmployee.username"
                  v-model:email="currentEmployee.email"
                  v-model:avatar="currentEmployee.avatar"
                  v-model:mobile="currentEmployee.phone_number"
                  v-model:role="currentEmployee.role"
                  :editable="editable"
                />
              </VCol>
              <VCol cols="8">
                <VTabs v-model="tab">
                  <VTab value="one">Послуги</VTab>
                  <VTab value="two">Робочі години</VTab>
                </VTabs>
                <VWindow v-model="tab">
                  <VWindowItem value="one">
                    <VContainer>
                      <VRow>
                        <VRow align="center" justify="space-between">
                          <VCol cols="9">
                            <VSelect
                              v-model="service_id"
                              :items="serviceItems"
                              item-title="name"
                              item-value="id"
                              label="Послуги"
                            >
                              <template #no-data>
                                <p>Послуг не знайдено</p>
                              </template>
                            </VSelect>
                          </VCol>
                          <VCol cols="3">
                            <VBtn @click="addEmployeeService"> Додати </VBtn>
                          </VCol>
                        </VRow>
                        <VCol cols="12">
                          <VTable>
                            <thead>
                              <tr>
                                <th>Назва</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="employee_services_attribute in employeeServices"
                                :key="employee_services_attribute?.id"
                              >
                                <td>{{ employee_services_attribute?.name }}</td>
                                <td>
                                  <VBtn
                                    icon
                                    @click="
                                      removeService(employee_services_attribute)
                                    "
                                  >
                                    <VIcon>mdi-delete</VIcon>
                                  </VBtn>
                                </td>
                              </tr>
                            </tbody>
                          </VTable>
                        </VCol>
                      </VRow>
                    </VContainer>
                  </VWindowItem>
                  <VWindowItem value="two">
                    <ScheduleList
                      @update:weekobject="onWeekObjectUpdate"
                      :schedules="currentEmployee.schedules_attributes"
                      is-employee
                      v-if="
                        currentEmployee.schedules_attributes &&
                        !companyStore.currentCompany?.custom_schedule
                      "
                    />
                    <div v-else>
                      <div class="">
                        <div class="table-view__table">
                          <div class="schedule__add">
                            <form ref="form" @submit="addSchedule">
                              <VueDatePicker
                                locale="uk"
                                hours-increment="1"
                                minutes-increment="5"
                                minutes-grid-increment="5"
                                class="schedule-item__timepicker"
                                :start-time="[
                                  { hours: 0, minutes: 0 },
                                  { hours: 23, minutes: 0 },
                                ]"
                                required
                                teleport="body"
                                :min-date="new Date()"
                                v-model="startDate"
                              />
                              <VueDatePicker
                                locale="uk"
                                hours-increment="1"
                                minutes-increment="5"
                                minutes-grid-increment="5"
                                class="schedule-item__timepicker"
                                :start-time="[
                                  { hours: 0, minutes: 0 },
                                  { hours: 23, minutes: 0 },
                                ]"
                                required
                                teleport="body"
                                :min-date="startDate"
                                v-model="endDate"
                              />
                              <VBtn type="submit">Додати графік</VBtn>
                            </form>
                          </div>
                          <div class="table-view__body">
                            <div class="table-view__header">
                              <div class="table-view__name">Початок</div>
                              <div class="table-view__name">Кінець</div>
                              <div></div>
                            </div>
                            <template
                              v-for="schedule in currentEmployee.schedules_attributes?.filter(
                                (s) =>
                                  s.custom_available_start && s._destroy != 1
                              )"
                            >
                              <div class="table-view__name">
                                {{
                                  humanizeDate(schedule.custom_available_start)
                                }}
                              </div>
                              <div>
                                {{
                                  humanizeDate(schedule.custom_available_end)
                                }}
                              </div>
                              <div class="table-view__actions">
                                <VBtn
                                  color="red"
                                  icon
                                  @click="openDeleteDialog(schedule.id!)"
                                >
                                  <v-icon small>mdi-delete</v-icon>
                                </VBtn>
                              </div>
                            </template>
                          </div>
                        </div>
                        <DeleteDialog
                          v-model="deleteDialog"
                          @delete="deleteSchedule"
                          @cancel="deleteDialog = false"
                        />
                      </div>
                    </div>
                  </VWindowItem>
                </VWindow>
              </VCol>
            </VRow>
          </VContainer>
        </VCardText>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="closeDialog(true)"
          >
            Відміна
          </v-btn>
          <v-btn color="blue-darken-1" variant="text" type="submit">
            Зберегти
          </v-btn>
        </v-card-actions>
      </VCard>
    </VForm>
  </VDialog>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { Employee } from "../../types/employee.type";
import EmployeeForm from "../employee-form.vue";
import ScheduleList from "../schedule-list.vue";
import DeleteDialog from "../delete-popup.vue";
import { WorkingHoursSchedule } from "@/main/types/working-hours-schedule.type";
import { useGlobalStore } from "@/main/stores/global.store";
import { humanizeDate } from "@/main/helpers/utils.helper";

import VueDatePicker from "@vuepic/vue-datepicker";
import { useCompanyStore } from "@/main/stores/company.store";

const globalStore = useGlobalStore();

const props = withDefaults(
  defineProps<{
    employee: Employee;
    opened: boolean;
    editable: boolean;
  }>(),
  { editable: false }
);

const currentEmployee = ref<Employee>({
  ...props.employee,
});

const companyStore = useCompanyStore();

const tab = ref("one");
const service_id = ref<number | null>(null);

const deleteDialog = ref(false);
const customScheduleId = ref<number>();
const startDate = ref<string>();
const endDate = ref<string>();

const employeeServices = computed(() => {
  return currentEmployee.value.employee_services_attributes
    ?.map((employee_services_attribute) => {
      const foundService = globalStore.services.find(
        (s) => s.id === employee_services_attribute.service_id
      );
      return {
        name: foundService?.name,
        id: employee_services_attribute.id,
        _destroy: employee_services_attribute._destroy,
      };
    })
    .filter((s) => s._destroy != 1);
});

const serviceItems = computed(() => {
  return globalStore.services.filter(
    (service) =>
      !currentEmployee.value.employee_services_attributes?.find(
        (s) => s.service_id === service.id
      )
  );
});

watch(props, (newValue, oldValue) => {
  if (newValue.opened) {
    currentEmployee.value = {
      ...currentEmployee.value,
      ...props.employee,
    } as Employee;
  }
  if (!newValue.opened) {
    tab.value = "one";
  }
});

const addEmployeeService = () => {
  if (service_id.value) {
    currentEmployee.value.employee_services_attributes = [
      ...(currentEmployee.value.employee_services_attributes || []),
      {
        service_id: service_id.value,
      },
    ];
    service_id.value = null;
  }
};

const removeService = (employee_services_attribute: any) => {
  currentEmployee.value.employee_services_attributes =
    currentEmployee.value.employee_services_attributes?.map((s) => {
      if (s.id === employee_services_attribute.id) {
        s._destroy = 1;
      }
      return s;
    });
};

const onWeekObjectUpdate = (workingHours: WorkingHoursSchedule[]) => {
  currentEmployee.value.schedules_attributes = workingHours;
};

const emit = defineEmits<{
  (e: "saveEmployee", value: Employee): void;
  (e: "closeWithoutSave"): void;
}>();

const closeDialog = (cancel: boolean = true) => {
  if (cancel) {
    emit("closeWithoutSave");
  } else {
    emit("saveEmployee", currentEmployee.value);
  }
  currentEmployee.value = {} as Employee;
};

const addSchedule = async (e: Event) => {
  e.preventDefault();
  await saveSchedule({
    _destroy: 0,
    custom_available_start: startDate.value,
    custom_available_end: endDate.value,
  });
};

const deleteSchedule = async () => {
  if (customScheduleId.value) {
    currentEmployee.value.schedules_attributes =
      currentEmployee.value.schedules_attributes?.map((s) => {
        if (s.id === customScheduleId.value) {
          s._destroy = 1;
        }
        return s;
      });
    deleteDialog.value = false;
  }
};

const saveSchedule = async (schedules_attributes?: WorkingHoursSchedule) => {
  try {
    const schedules = schedules_attributes
      ? [
          ...(currentEmployee.value.schedules_attributes || []),
          schedules_attributes,
        ]
      : currentEmployee.value.schedules_attributes;

    currentEmployee.value.schedules_attributes = schedules;
  } catch (error) {
    console.error(error);
  }
  startDate.value = "";
  endDate.value = "";
};

const openDeleteDialog = (id: number) => {
  deleteDialog.value = true;
  customScheduleId.value = id;
};
</script>

<style lang="scss" scoped>
.schedule {
  &__add {
    form {
      display: flex;
      column-gap: 30px;
      padding: 10px 0;
    }
  }

  :deep(.v-input__details) {
    display: none;
  }
}
.table-view {
  h3 {
    margin-bottom: 0;
  }

  &__header {
    grid-template-columns: repeat(3, 1fr);
  }

  &__body {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
