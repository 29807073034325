<template>
  <div class="account">
    <VTabs v-model="activeTab">
      <VTab value="general">Основні</VTab>
      <VTab value="boost">Boost!</VTab>
      <VTab value="subsc">Підписка</VTab>
    </VTabs>

    <VWindow v-model="activeTab">
      <VWindowItem value="general">
        <v-card v-if="loadingUser">
          <VProgressCircular indeterminate color="primary" />
        </v-card>
        <v-card v-if="!loadingUser">
          <v-card-title>Загальна інформація</v-card-title>
          <v-card-text>
            <div>
              <p><strong>Ім'я:</strong> {{ user.name }}</p>
              <p><strong>Email:</strong> {{ user.email }}</p>
            </div>
            <v-form ref="form" v-model="valid">
              <VTextField
                v-model="oldPassword"
                :rules="passwordRules"
                label="Теперешній пароль"
                type="password"
                :disabled="loading"
              />
              <v-text-field
                v-model="password"
                :rules="passwordRules"
                label="Новий пароль"
                type="password"
                :disabled="loading"
              />
              <v-text-field
                v-model="passwordConfirm"
                :rules="passwordRules"
                label="Повторіть новий пароль"
                type="password"
                :disabled="loading"
              />
              <v-btn :disabled="!valid" @click="updateInfo" :loading="loading"
                >Змінити</v-btn
              >
            </v-form>
          </v-card-text>
        </v-card>
      </VWindowItem>

      <VWindowItem value="boost">
        <v-card flat>
          <v-card-text class="account__boost">
            <img :src="boostImg" alt="Boost Image" />
            <div class="boost__text">
              <h3>Функція Boost доступна лише в підписці Бізнес PRO.</h3>
              <p>
                З послугою Boost профіль Вашої компанії автоматично потрапляє в
                рекомендації на головній сторінці додатку на два тижні. У вас
                також з’явиться можливість додати ваучери для покупки та
                розігрувати GIFT карти серед своїх клієнтів.
              </p>
            </div>
          </v-card-text>
        </v-card>
      </VWindowItem>

      <VWindowItem value="subsc">
        <v-card flat>
          <v-card-title>Subscription</v-card-title>
          <v-card-text>
            <div v-if="user.subscription">
              <p>
                <strong>Current Subscription:</strong> {{ user.subscription }}
              </p>
            </div>
            <div v-else>
              <p>No current subscription. Available subscriptions:</p>
              <ul>
                <li v-for="sub in availableSubscriptions" :key="sub.id">
                  {{ sub.name }}
                </li>
              </ul>
            </div>
          </v-card-text>
        </v-card>
      </VWindowItem>
    </VWindow>
  </div>
</template>

<script setup lang="ts">
import {
  getCurrentUser,
  updateCurrentUserPassword,
} from "@/main/services/api.service";
import { User } from "@/main/types/user.type";
import { onMounted, ref } from "vue";

import boostImg from "@/main/assets/img/boost.svg";

const activeTab = ref(0);
const user = ref<User>({
  name: "",
  email: "",
  subscription: {
    id: 0,
    plan_id: 0,
    paid: false,
    start_date: "",
    end_date: "",
    status: "",
  },
  username: "",
  first_name: "",
  last_name: "",
  phone_number: "",
  address: "",
  avatar: "",
});
const password = ref("");
const oldPassword = ref("");
const passwordConfirm = ref("");
const phoneNumber = ref("");
const valid = ref(false);
const loading = ref(false);
const loadingUser = ref(false);

onMounted(async () => {
  loadingUser.value = true;
  user.value = await getCurrentUser();
  loadingUser.value = false;
});
const passwordRules = [
  (v: any) => !!v || "Поле обов'язкове",
  (v: string | any[]) =>
    v.length >= 6 || "Пароль повинен бути не менше 6 символів",
];
const phoneRules = [
  (v: any) => !!v || "Phone number is required",
  (v: string) => /^\d{9}$/.test(v) || "Phone number must be 10 digits",
];
const availableSubscriptions = ref([
  { id: 1, name: "Basic Plan" },
  { id: 2, name: "Premium Plan" },
  { id: 3, name: "Pro Plan" },
]);

const updateInfo = async () => {
  loading.value = true;
  const result = await updateCurrentUserPassword(
    oldPassword.value,
    password.value,
    passwordConfirm.value
  );

  if (result) {
    password.value = "";
    oldPassword.value = "";
    passwordConfirm.value = "";
  } else {
    console.log("Error updating user information");
  }
  loading.value = false;
};
</script>

<style scoped lang="scss">
.account {
  width: 100%;
  height: auto;
  padding: 32px;

  &__boost {
    display: flex;
    justify-content: center;
    align-items: center;

    .boost__text {
      margin-top: 16px;
      text-align: center;
    }
  }
}
</style>
