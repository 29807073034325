import { defineStore } from "pinia";
import { Subscription, User } from "../types/user.type";

interface UserState {
  user: User | undefined;
  subscription: Subscription | null;
}

export const useUserStore = defineStore("user", {
  state: (): UserState => ({
    user: undefined,
    subscription: null,
  }),
  persist: {
    paths: [],
  },
});
