<template>
  <div class="customers">
    <div class="customers__content">
      <v-data-table-server
        :headers="headers"
        :items="customers"
        :search="search"
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        :items-length="totalItems"
        :loading="loading"
        :items-per-page-options="perPageChoices"
        :items-per-page-text="'Записів на сторінці'"
        @update:options="updatedOptions"
        class="elevation-1 customers__table"
      >
        <template v-slot:top>
          <v-toolbar flat color="transparent">
            <v-toolbar-title>Клієнти</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Пошук"
              single-line
              hide-details
              variant="outlined"
            ></v-text-field>
          </v-toolbar>
        </template>
        <!-- <template v-slot:bottom>
          <VContainer>
            <VRow class="customers__table-bottom">
              <VCol cols="12" sm="8" />
              <VCol cols="12" sm="2">
                <v-select
                  v-model="itemsPerPage"
                  :items="perPageChoices"
                  variant="outlined"
                  label="Items per page"
                ></v-select>
              </VCol>
              <VCol cols="12" sm="2">
                <v-pagination
                  v-model="page"
                  total-visible="3"
                  :length="totalPages"
                ></v-pagination>
              </VCol>
            </VRow>
          </VContainer>
        </template> -->
        <!-- <template v-slot:item.actions="{ item }">
          <v-icon small @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template> -->
      </v-data-table-server>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { CompanyCustomer } from "../types/company.type";
import { getCompanyCustomers } from "../services/api.service";
import { useCompanyStore } from "../stores/company.store";
import moment from "moment";
import { VContainer } from "vuetify/lib/components/index.mjs";

const loading = ref(false);

const headers = [
  { title: "ФІО", value: "name", align: "start" as const },
  { title: "Email", value: "email" },
  { title: "Номер телефону", value: "phone_number" },
  { title: "Кількість візитів", value: "visit_count" },
  {
    title: "Дата останнього візиту",
    key: "last_visit",
    value: "last_visit",
  },
  {
    title: "Крайня послуга",
    value: "last_service_name",
  },
  // { title: "Actions", align: "end" as const, value: "actions", sortable: false },
];

const companyStore = useCompanyStore();

const customers = ref<CompanyCustomer[]>([]);

const search = ref("");
const itemsPerPage = ref(10);
const page = ref(1);
const totalItems = ref(0);

const perPageChoices = ref([
  { title: "10", value: 1 },
  { title: "20", value: 20 },
  { title: "50", value: 50 },
]);

onMounted(async () => {
  // Fetch customers
  loading.value = true;
  if (companyStore.currentCompany) {
    const customersResponse = await getCompanyCustomers(
      companyStore.currentCompany?.id,
      itemsPerPage.value
    );
    customers.value = customersResponse.customers;
    totalItems.value = customersResponse.pages.itemsLength;
  }
  loading.value = false;
});

const editItem = (item: any) => {
  // Edit item logic
};

const deleteItem = (item: any) => {
  // Delete item logic
};

const updatedOptions = async (options: {
  page: number;
  itemsPerPage: number | undefined;
}) => {
  loading.value = true;
  page.value = options.page;
  if (companyStore.currentCompany) {
    const customersRes = await getCompanyCustomers(
      companyStore.currentCompany?.id,
      options.itemsPerPage,
      page.value
    );

    customers.value = customersRes.customers;
    totalItems.value = customersRes.pages.itemsLength;
  }
  loading.value = false;
};
</script>
<style lang="scss">
.customers {
  height: calc(95vh - 126px);
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  column-gap: 20px;

  &__content,
  &__sidebar {
    width: 100%;
    border-radius: 20px;
    background: #fff;
  }

  &__content {
    padding: 10px;
  }

  &__table {
    border-radius: 20px;
    height: 100%;

    &-bottom {
      align-items: center;
    }
  }
}
</style>
